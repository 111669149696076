






















import { SimplyVideoAdminLabel, distributorLabel, resellerLabel } from '@/router';
import Component from 'vue-class-component';
import {Store} from '@/store/store'

@Component
export default class DEBUG extends Store {
  get $debug(){
    return (process.env.VUE_APP_DEBUG) && process.env.NODE_ENV !== 'production' 
  }

  get backendUrl(){

    let SAAS_basePath = process.env.VUE_APP_API_URL
    if(!SAAS_basePath){
      SAAS_basePath = 'https://api.' + window.location.host + '/api/'
    }
    return SAAS_basePath;
  }


  forceSetAccountType(type: string){
    if(type == SimplyVideoAdminLabel){
      this.$store.dispatch('mock/setSVA')
    }
    else if(type == distributorLabel){

      this.$store.dispatch('mock/setDist')
    }
    else if(type == resellerLabel){

      this.$store.dispatch('mock/setRes')
    }
    
  }
}
