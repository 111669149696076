export async function fetchConfigAndStoreLocally() {
    try {
      const response = await fetch('/config.json');
  
      if (!response.ok) {
        throw new Error('Failed to fetch config.json');
      }
  
      const config = await response.json();
      // Store the config in local storage
      localStorage.setItem('config', JSON.stringify(config));
      console.log('inside try fetch');
    } catch (error) {
        localStorage.removeItem('config');
      console.error('Error fetching config.json:', error);
      console.log('inside catch fetch');
      throw error;
    }
  }

  export function getConfigFromLocalStorage() {
    const storedConfigString = localStorage.getItem('config');
    console.log('storedConfigString', storedConfigString);
    return storedConfigString ? JSON.parse(storedConfigString) : null;
  }
  console.log('getConfigFromLocalStorage', getConfigFromLocalStorage());
